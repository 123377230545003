// This is the scss entry file
import "../styles/index.scss";

import "../components/components.js";
import "./common.js";
import Alpine from 'alpinejs'
import ajax from '@imacrayon/alpine-ajax'
import "lazysizes";

window.Alpine = Alpine
Alpine.plugin(ajax)
Alpine.start();