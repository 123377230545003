window.bgToRemove = ['bg-white', 'bg-amber-50', 'bg-gray-50', 'bg-gray-700', 'bg-black', 'dark:bg-white', 'dark:bg-amber-50', 'dark:bg-gray-50', 'dark:bg-gray-700', 'dark:bg-black'];
window.colorToRemove = ['text-white', 'text-yellow-600', 'text-black', 'text-gray-700', 'text-red-700', 'text-green-700', 'text-blue-700', 'dark:text-white', 'dark:text-yellow-600', 'dark:text-black', 'dark:text-gray-700', 'dark:text-red-700', 'dark:text-green-700', 'dark:text-blue-700'];

const initTextChapterPage = function () {
    // Default values
    const defaultFont = 'beiruti';
    const defaultSize = 'text-3xl';
    const defaultLeading = 'leading-[3rem]';
    let defaultColor = 'text-gray-800';
    let defaultBg = 'bg-white';

    // Function to apply stored preferences
    function applyPreferences() {
        const storedFont = localStorage.getItem('selectedFont') || defaultFont;
        const storedSize = localStorage.getItem('selectedSize') || defaultSize;
        const storedColor = localStorage.getItem('selectedColor') || defaultColor;
        const storedBg = localStorage.getItem('selectedBg') || defaultBg;
        const storedLeading = localStorage.getItem('selectedLeading') || defaultLeading;


        // Apply stored classes to the elements
        const readingContent = document.getElementById('reading-content');
        const fontSelect = document.getElementById('font-select');
        const chapterPage = document.getElementById('text-chapter-page');
        const readingUpdate = document.querySelectorAll('.reading-update');
        const readingTitle = document.getElementById('reading-title');
        const leadingSelect = document.getElementById('leading-select');
        const sizeSelect = document.getElementById('size-select');
        const colorSelect = document.getElementById('color-select');
        const bgSelect = document.getElementById('bg-select');

        if (readingContent) {
            readingContent.classList.add(storedSize, storedLeading, storedFont);
        }

        if(readingUpdate) {
            readingUpdate.forEach(el => {
                el.classList.remove(...window.colorToRemove);
                el.classList.add(storedColor);
            });
        }

        if (chapterPage) {
            chapterPage.classList.add(storedBg);
        }

        if (readingTitle) {
            readingTitle.classList.add(`${storedFont}-title`);
        }

        if (fontSelect) {
            fontSelect.value = storedFont;
        }

        if (leadingSelect) {
            leadingSelect.value = storedLeading;
        }

        if (sizeSelect) {
            sizeSelect.value = storedSize;
        }

        if (colorSelect) {
            colorSelect.value = storedColor;
        }

        if (bgSelect) {
            bgSelect.value = storedBg;
        }
    }

    applyPreferences();

    const fontSelect = document.getElementById('font-select');
    if (fontSelect) {
        fontSelect.addEventListener('change', function (e) {
            const fontToRemove = ['josefin-sans', 'roboto-mono', 'beiruti', 'montserrat', 'roboto', 'cormorant'];
            const titleFontToRemove = ['josefin-sans-title', 'roboto-mono-title', 'beiruti-title', 'montserrat-title', 'roboto-title', 'cormorant-title'];
            const selectedFont = e.target.value;

            // Remove specific classes from the elements
            const readingTitle = document.getElementById('reading-title');
            if (readingTitle) {
                readingTitle.classList.remove(...titleFontToRemove);
                readingTitle.classList.add(`${selectedFont}-title`);
            }
            const readingContent = document.getElementById('reading-content');
            if (readingContent) {
                readingContent.classList.remove(...fontToRemove);
                readingContent.classList.add(selectedFont);
            }

            // Store the selected font in localStorage
            localStorage.setItem('selectedFont', selectedFont);
        });
    }

    const sizeSelect = document.getElementById('size-select');
    if (sizeSelect) {
        sizeSelect.addEventListener('change', function (e) {
            const sizeToRemove = ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl'];
            const selectedSize = e.target.value;

            // Remove size classes from the elements
            const readingContent = document.getElementById('reading-content');
            if (readingContent) {
                readingContent.classList.remove(...sizeToRemove);
                readingContent.classList.add(selectedSize);
            }

            // Store the selected size in localStorage
            localStorage.setItem('selectedSize', selectedSize);
        });
    }

    const leadingSelect = document.getElementById('leading-select');
    if (leadingSelect) {
        leadingSelect.addEventListener('change', function (e) {
            const leadingToRemove = ['leading-8', 'leading-10', 'leading-[3rem]', 'leading-[4rem]', 'leading-[5rem]'];
            const selectedLeading = e.target.value;

            // Remove leading classes from the elements
            const readingContent = document.getElementById('reading-content');
            if (readingContent) {
                readingContent.classList.remove(...leadingToRemove);
                readingContent.classList.add(selectedLeading);
            }

            // Store the selected leading in localStorage
            localStorage.setItem('selectedLeading', selectedLeading);
        });
    }

    const colorSelect = document.getElementById('color-select');
    if (colorSelect) {
        colorSelect.addEventListener('change', function (e) {
            const currentTheme = localStorage.getItem('theme');
            const selectedColor = e.target.value;
            let themeSelectedColor = selectedColor;

            if (currentTheme === 'dark') {
                themeSelectedColor = 'dark:' + selectedColor;
            }

            // Remove color classes from the elements
            document.querySelectorAll('.reading-update').forEach(el => {
                el.classList.remove(...window.colorToRemove);
                el.classList.add(themeSelectedColor);
            });

            // Store the selected color in localStorage
            localStorage.setItem('selectedColor', selectedColor);
        });
    }

    const bgSelect = document.getElementById('bg-select');
    if (bgSelect) {
        bgSelect.addEventListener('change', function (e) {
            const currentTheme = localStorage.getItem('theme');
            const selectedBg = e.target.value;
            let themeSelectedBg = selectedBg;

            if (currentTheme === 'dark') {
                themeSelectedBg = 'dark:' + selectedBg;
            }

            // Remove background classes from the elements
            const chapterPage = document.getElementById('text-chapter-page');
            if (chapterPage) {
                chapterPage.classList.remove(...window.bgToRemove);
                chapterPage.classList.add(themeSelectedBg);
            }

            // Store the selected background in localStorage
            localStorage.setItem('selectedBg', selectedBg);
        });
    }
}

const initCommon = function () {
    initTextChapterPage();

    document.querySelectorAll('span').forEach(function (span) {
        if (span.textContent.trim() === 'Root') {
            span.classList.add('hide');
        }
    });

    const divs = document.querySelectorAll('div[data-onload="hidden"]');
    if (divs) {
        divs.forEach(div => {
            div.classList.remove('hidden');
        });
    }

    function getQueryParam(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    document.querySelectorAll('.nav-item').forEach(function (limk) {
        if (limk.getAttribute('data-active') === getQueryParam('category')) {
            limk.classList.add('text-yellow-600');
        }
    });

    const backToTop = document.getElementById("back-to-top");
    if (backToTop) {
        backToTop.addEventListener("click", function () {
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
    }

    const chapterSearch = document.getElementById('chapter-search');
    const sortAsc = document.getElementById('sort-asc');
    const sortDesc = document.getElementById('sort-desc');

   // Handle sorting
    function toggleSortDirection() {
        const currentSort = sortAsc.classList.contains('hidden') ? 'desc' : 'asc';
        const newSort = currentSort === 'asc' ? 'desc' : 'asc';

        if (newSort === 'asc') {
            sortAsc.classList.remove('hidden');
            sortDesc.classList.add('hidden');
        } else {
            sortAsc.classList.add('hidden');
            sortDesc.classList.remove('hidden');
        }

        sortChapters(newSort);
        window.scrollToChapter();
    }

    // Event listener for sorting
    if (sortAsc || sortDesc) {
        [sortAsc, sortDesc].forEach(function(sortElement) {
            sortElement.addEventListener('click', toggleSortDirection);
        });
    }

    // Function to sort chapters
    function sortChapters(sortDirection) {
         let chapters = Array.from(document.getElementsByClassName('chapter-item'));
         chapters.sort((a, b) => {
            const indexA = parseInt(a.getAttribute('data-index'), 10);
            const indexB = parseInt(b.getAttribute('data-index'), 10);

            return sortDirection === 'asc' ? indexA - indexB : indexB - indexA;
        });

        // Re-render sorted chapters
        const chapterList = document.getElementById('chapter-list');
        chapterList.innerHTML = '';
        chapters.forEach(chapter => chapterList.appendChild(chapter));
    }

    if (chapterSearch) {
        chapterSearch.addEventListener('keyup', function () {
            const filter = this.value.toLowerCase();
            const chapters = document.getElementsByClassName('chapter-item');

            Array.prototype.forEach.call(chapters, function (chapter) {
                const chapterName = chapter.textContent || chapter.innerText;
                if (chapterName.toLowerCase().indexOf(filter) > -1) {
                    chapter.style.display = "";
                } else {
                    chapter.style.display = "none";
                }
            });
        });
    }

    window.scrollToChapter = function () {
        setTimeout(() => {
            const currentChapter = document.querySelector('#current-chapter');
            const chapterList = document.querySelector('#chapter-list');
            if (currentChapter && chapterList) {
                const chapterOffsetTop = currentChapter.offsetTop - 73;
                chapterList.scrollTo({top: chapterOffsetTop, behavior: 'smooth'});
            }
        }, 300);
    }

    document.addEventListener('keydown', function(event) {
        switch (event.key) {
            case 'ArrowRight': {
                const nextChapter = document.getElementById('next-chapter');
                if (nextChapter) {
                    nextChapter.click();
                }
                break;
            }
            case 'ArrowLeft': {
                const prevChapter = document.getElementById('prev-chapter');
                if (prevChapter) {
                    prevChapter.click();
                }
                break;
            }
            default:
                break;
        }
    });

    const removeCommentedParam = function() {
        // Create a URL object from the current URL
        const url = new URL(window.location.href);

        // Get the search parameters
        const params = new URLSearchParams(url.search);

        // Remove the 'commented' parameter
        params.delete('commented');

        // Update the URL without reloading the page
        const newUrl = `${url.pathname}?${params.toString()}`;
        history.replaceState(null, '', newUrl);
    }
}

document.addEventListener('DOMContentLoaded', function() {
    initCommon();
})
